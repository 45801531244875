<template>
  <div>
    <v-row align="center">
      <v-col cols="auto">
        <h2>Images</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Welcome', itinerary.welcome_image)"
            :src="imageUrl(itinerary.welcome_image, 'Welcome')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Welcome</v-card-title>
            <v-chip label v-if="!itinerary.welcome_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Welcome</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Welcome',
                  itinerary.welcome_image ? itinerary.welcome_image.id : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Itinerary', itinerary.itinerary_image)"
            :src="imageUrl(itinerary.itinerary_image, 'Itinerary')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Itinerary</v-card-title>
            <v-chip label v-if="!itinerary.itinerary_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Itinerary</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Itinerary',
                  itinerary.itinerary_image
                    ? itinerary.itinerary_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="
              imageIsAvailable('Accommodation', itinerary.accommodation_image)
            "
            :src="imageUrl(itinerary.accommodation_image, 'Accommodation')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Accommodation</v-card-title>
            <v-chip
              label
              v-if="!itinerary.accommodation_image"
              class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Accommodation</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Accommodation',
                  itinerary.accommodation_image
                    ? itinerary.accommodation_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="
              imageIsAvailable('Golf Courses', itinerary.golf_courses_image)
            "
            :src="imageUrl(itinerary.golf_courses_image, 'Golf Courses')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Golf Courses</v-card-title>
            <v-chip label v-if="!itinerary.golf_courses_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Golf Courses</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Golf Courses',
                  itinerary.golf_courses_image
                    ? itinerary.golf_courses_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Events', itinerary.events_image)"
            :src="imageUrl(itinerary.events_image, 'Events')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Events</v-card-title>
            <v-chip label v-if="!itinerary.events_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Events</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Events',
                  itinerary.events_image ? itinerary.events_image.id : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Sightseeing', itinerary.sightseeing_image)"
            :src="imageUrl(itinerary.sightseeing_image, 'Sightseeing')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Sightseeing</v-card-title>
            <v-chip label v-if="!itinerary.sightseeing_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Sightseeing</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Sightseeing',
                  itinerary.sightseeing_image
                    ? itinerary.sightseeing_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Restaurants', itinerary.restaurant_image)"
            :src="imageUrl(itinerary.restaurant_image, 'Restaurants')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Restaurants</v-card-title>
            <v-chip label v-if="!itinerary.restaurant_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Restaurants</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Restaurants',
                  itinerary.restaurant_image
                    ? itinerary.restaurant_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="
              imageIsAvailable('Transportation', itinerary.transportation_image)
            "
            :src="imageUrl(itinerary.transportation_image, 'Transportation')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Transportation</v-card-title>
            <v-chip
              label
              v-if="!itinerary.transportation_image"
              class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Transportation</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Transportation',
                  itinerary.transportation_image
                    ? itinerary.transportation_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Map', itinerary.map_image)"
            :src="imageUrl(itinerary.map_image, 'Map')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Map</v-card-title>
            <v-chip label v-if="!itinerary.map_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            src="@/assets/placeholder.svg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Map</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Map',
                  itinerary.map_image ? itinerary.map_image.id : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-img
            v-if="imageIsAvailable('Next Step', itinerary.next_step_image)"
            :src="imageUrl(itinerary.next_step_image, 'Next Step')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Next Step</v-card-title>
            <v-chip label v-if="!itinerary.next_step_image" class="ml-4 mb-4"
              >Using Default Image</v-chip
            >
          </v-img>
          <v-img
            v-else
            :src="placeholder"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
          >
            <v-card-title>Next Step</v-card-title>
            <v-card-subtitle class="white--text">
              Please choose
            </v-card-subtitle>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                $refs.imageSelector.openDialog(
                  'Next Step',
                  itinerary.next_step_image
                    ? itinerary.next_step_image.id
                    : null
                )
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ImageSelector ref="imageSelector" />
  </div>
</template>

<script>
import ImageSelector from "../../components/ImageSelector";

export default {
  components: {
    ImageSelector,
  },

  props: {
    itinerary: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      placeholder: require("@/assets/placeholder.svg"),
    };
  },

  computed: {
    categories() {
      return this.$store.state.drum.admin["imageCategories"];
    },
  },

  methods: {
    imageUrl(image, typeName) {
      if (image === null) {
        const index = this.categories.findIndex((c) => c.name === typeName);
        return this.categories[index].default_image.asset_urls.thumbnail;
      }

      return image.asset_urls.thumbnail;
    },

    imageIsAvailable(typeName, selectedImage) {
      if (selectedImage !== null) {
        return true;
      }

      const index = this.categories.findIndex((c) => c.name === typeName);
      if (this.categories[index].default_image_id !== null) {
        return true;
      }

      return false;
    },
  },
};
</script>