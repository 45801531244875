var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Images")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Welcome', _vm.itinerary.welcome_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.welcome_image, 'Welcome'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Welcome")]),(!_vm.itinerary.welcome_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Welcome")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Welcome',
                _vm.itinerary.welcome_image ? _vm.itinerary.welcome_image.id : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Itinerary', _vm.itinerary.itinerary_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.itinerary_image, 'Itinerary'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Itinerary")]),(!_vm.itinerary.itinerary_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Itinerary")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Itinerary',
                _vm.itinerary.itinerary_image
                  ? _vm.itinerary.itinerary_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(
            _vm.imageIsAvailable('Accommodation', _vm.itinerary.accommodation_image)
          )?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.accommodation_image, 'Accommodation'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Accommodation")]),(!_vm.itinerary.accommodation_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Accommodation")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Accommodation',
                _vm.itinerary.accommodation_image
                  ? _vm.itinerary.accommodation_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(
            _vm.imageIsAvailable('Golf Courses', _vm.itinerary.golf_courses_image)
          )?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.golf_courses_image, 'Golf Courses'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Golf Courses")]),(!_vm.itinerary.golf_courses_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Golf Courses")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Golf Courses',
                _vm.itinerary.golf_courses_image
                  ? _vm.itinerary.golf_courses_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Events', _vm.itinerary.events_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.events_image, 'Events'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Events")]),(!_vm.itinerary.events_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Events")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Events',
                _vm.itinerary.events_image ? _vm.itinerary.events_image.id : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Sightseeing', _vm.itinerary.sightseeing_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.sightseeing_image, 'Sightseeing'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Sightseeing")]),(!_vm.itinerary.sightseeing_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Sightseeing")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Sightseeing',
                _vm.itinerary.sightseeing_image
                  ? _vm.itinerary.sightseeing_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Restaurants', _vm.itinerary.restaurant_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.restaurant_image, 'Restaurants'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Restaurants")]),(!_vm.itinerary.restaurant_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Restaurants")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Restaurants',
                _vm.itinerary.restaurant_image
                  ? _vm.itinerary.restaurant_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(
            _vm.imageIsAvailable('Transportation', _vm.itinerary.transportation_image)
          )?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.transportation_image, 'Transportation'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Transportation")]),(!_vm.itinerary.transportation_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Transportation")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Transportation',
                _vm.itinerary.transportation_image
                  ? _vm.itinerary.transportation_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Map', _vm.itinerary.map_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.map_image, 'Map'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Map")]),(!_vm.itinerary.map_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":require("@/assets/placeholder.svg"),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Map")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Map',
                _vm.itinerary.map_image ? _vm.itinerary.map_image.id : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[(_vm.imageIsAvailable('Next Step', _vm.itinerary.next_step_image))?_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.imageUrl(_vm.itinerary.next_step_image, 'Next Step'),"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Next Step")]),(!_vm.itinerary.next_step_image)?_c('v-chip',{staticClass:"ml-4 mb-4",attrs:{"label":""}},[_vm._v("Using Default Image")]):_vm._e()],1):_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.placeholder,"gradient":"to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"}},[_c('v-card-title',[_vm._v("Next Step")]),_c('v-card-subtitle',{staticClass:"white--text"},[_vm._v(" Please choose ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.imageSelector.openDialog(
                'Next Step',
                _vm.itinerary.next_step_image
                  ? _vm.itinerary.next_step_image.id
                  : null
              )}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1)],1),_c('ImageSelector',{ref:"imageSelector"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }